import React from "react"
import HomeContainer from "../containers/HomeContainer"
import HeadData from "../data/HeadData"
import SkewedHeaderBg from "../headers/SkewedHeaderBg"
import PageDemoFullHeaderColorBg from "../headers/PageDemoFullHeaderColorBg"
import HeaderNav from "../navigation/HeaderNav"
import PageDemoHeroText from "../headers/PageDemoHeroText"

export default () => (
  <HomeContainer>
    <HeadData title="Request Demo" />

    <SkewedHeaderBg />

    <PageDemoFullHeaderColorBg>
      <HeaderNav isHome />
      <PageDemoHeroText 
        title="The Master Growers #1 choice for simplified compliance reporting,
        inventory management, and grow optimization software."
        jotFormId="220651548291456"
      >
        <h2 className="sm:w-full md:w-full w-3/4 font-body font-medium text-xl sm:text-lg text-grolens-green-800 mb-6 sm:mb-8">
          Connect with a GroLens expert to discuss how we can improve your
          workflow in your commercial grow operation.
        </h2>
      </PageDemoHeroText>
    </PageDemoFullHeaderColorBg>
  </HomeContainer>
)
